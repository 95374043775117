import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import { Helmet } from "react-helmet";
import Footer from "../../component/footer/Footer";
import CustomLine from "../../elements/CustomLine";
import { BackButton } from "../../component/common/BackButton";

const bottomImageUrl = (
  <img src="/assets/images/products/products_15.png" alt="Logo header" />
);

const bottomImageMobileUrl = (
  <img src="/assets/images/products/products_15_mobile.png" alt="Logo header" />
);

export default function CorporateVentureCapital() {
  return (
    <div className="active-dark bg_color--10">
      <Helmet pageTitle="Vertical Bank - Corporate Venture Capital" />

      {/* Start Header Area  */}
      <Header />
      <BackButton></BackButton>
      {/* End Header Area  */}
      {/* Start Slider Area   */}
      <div className="product-wrapper">
        <div
          className="product products-style fullscreen d-flex align-items-center justify-content-center bg_image bg_image--products-10"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="offset-1 col-lg-6">
                <div className="inner">
                  <CustomLine lines={[{ width: "80%" }, { width: "80%" }]} />
                  <h1 className="title">CORPORATE VENTURE CAPITAL</h1>
                  <CustomLine lines={[{ width: "100%" }]} />
                  <p className="text">
                    {" "}
                    Investimento destinado à aceleração de empresas. <br />{" "}
                    Atuamos nos segmentos Agro e Financeiro.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="only-md product multi-box-content d-flex align-items-center justify-content-center">
          {bottomImageUrl}
        </div>

        <div className="only-sm product multi-box-content d-flex align-items-center justify-content-center">
          {bottomImageMobileUrl}
        </div>
      </div>

      {/* End Slider Area   */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
}
