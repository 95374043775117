import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import { Helmet } from "react-helmet";
import Footer from "../../component/footer/Footer";
import CustomLine from "../../elements/CustomLine";
import { BackButton } from "../../component/common/BackButton";

class RiscoInfo extends Component {
  render() {
    const { title, text } = this.props;

    return (
      <React.Fragment>
        <div className="risco-info">
          <p className="risco-info-title">{title}</p>
          <p className="risco-info-text">{text}</p>
        </div>
      </React.Fragment>
    );
  }
}

const bottomImageUrl = (
  <img src="/assets/images/products/products_17.png" alt="Logo header" />
);

export default function RiscoSacado() {
  var riscoFirst = [
    {
      title: "Melhor Fluxo Financeiro",
      text: "Aumento de prazo de pagamento aos fornecedores.",
    },
    {
      title: "Centralização de pagamentos",
      text: "Utilização de canal único de pagamentos, evitando acompanhamento de vários canais.",
    },
    {
      title: "Segurança",
      text: "Títulos descontados somente com a anuência da empresa.",
    },
    {
      title: "Mesa especializada para convênio",
      text: "Canal exclusivo para empresas âncora, fornecendo atendimento personalizado.",
    },
    {
      title: "Fornecedor não passa por aprovação",
      text: "Cadastro ágil e descomplicado.",
    },
  ];

  var riscoSecond = [
    {
      title: "Centralização de Recebimentos",
      text: "Utilização de canal único de recebimento, sem necessidade de buscar parceiros.",
    },
    {
      title: "Crédito descomplicado",
      text: "Títulos já validados, sem necessidade de avaliação de crédito.",
    },
    {
      title: "Agilidade",
      text: "Crédito realizado sem burocracia em D+1.",
    },
    {
      title: "Mesa especializada para convênio",
      text: "Canal exclusivo para fornecedores que possuem convênio âncora, fornecendo atendimento personalizado.",
    },
  ];

  return (
    <div className="active-dark bg_color--10">
      <Helmet pageTitle="Home Portfolio" />

      <Header />
      <BackButton></BackButton>
      <div className="product-wrapper">
        <div
          className="product products-style fullscreen d-flex align-items-center bg_image bg_image--products-18"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row risco-sacado offset-1">
              <div className="col-lg-7">
                <CustomLine lines={[{ width: "75%" }, { width: "75%" }]} />
                <h1 className="risco-title">
                  Risco <br /> Sacado
                </h1>
                <CustomLine lines={[{ width: "75%" }]} />
                <p className="risco-description"> Antecipação à Fornecedores</p>
                <p className="risco-text">
                  Possibilidade de antecipação para fornecedores de Grandes
                  Empresas. O produto certo para melhorar o seu relacionamento
                  com o fornecedor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="risco-green-section">
        <div className="container">
          <h1 className="offset-1">Antecipação aos fornecedores_</h1>
        </div>
      </div>

      <div
        className="product risco-bottom-section d-flex"
        data-black-overlay="6"
      >
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-10">
                <CustomLine
                  color="black"
                  orientation="center"
                  lines={[{ width: "70%" }]}
                />
                <CustomLine color="black" lines={[{ width: "75%" }]} />
              </div>
            </div>

            <div className="row">
              <div className="offset-md-1 col-12 col-md-5">
                <p className="title">Empresa âncora</p>
                {riscoFirst &&
                  riscoFirst.map((item) => (
                    <RiscoInfo title={item.title} text={item.text} />
                  ))}
              </div>
              <div className="offset-md-1 col-12 col-md-5">
                <p className="title">Aos fornecedores</p>
                {riscoSecond &&
                  riscoSecond.map((item) => (
                    <RiscoInfo title={item.title} text={item.text} />
                  ))}

                <div className="ptb--30">
                  <CustomLine
                    orientation="flex-end"
                    color="black"
                    lines={[{ width: "130%" }]}
                  />
                </div>
                <CustomLine
                  orientation="flex-end"
                  color="black"
                  lines={[{ width: "90%" }, { width: "100%" }]}
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="risco-centralizacao-section">
        <div className="container">
          <h1 className="offset-md-1 centralizacao-title">
            Centralização de Pagamentos_
          </h1>
        </div>
      </div>

      <div className="product risco-bottom-section d-flex align-items-center justify-content-center">
        {bottomImageUrl}
      </div>

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
}
