import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { color = "default-color" } = this.props;
    const logoUrl = (
      <img
        src="/assets/images/svg/logo_vertical_white_text.png"
        alt="Logo header"
      />
    );

    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <hr className="header-line" />
        <div className="header-wrapper container">
          <div className="header-left d-flex align-items-center">
            <div className="logo ml--50">
              <a href={this.props.homeLink}>{logoUrl}</a>
            </div>
            <nav className="mainmenunav d-lg-block">
              <Scrollspy
                className="mainmenu"
                items={["home", "about", "values", "products"]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/sobre">Quem somos</Link>
                </li>
                <li>
                  <Link to="/valores">Propósitos e Valores</Link>
                </li>
                <li>
                  <Link to="/produtos">Produtos</Link>
                </li>
              </Scrollspy>
            </nav>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <Scrollspy
                className="mainmenu"
                items={["open"]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  <div className="only-sm">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/sobre">Quem somos</Link>
                    </li>
                    <li>
                      <Link to="/valores">Propósitos e Valores</Link>
                    </li>
                    <li>
                      <Link to="/produtos">Produtos</Link>
                    </li>
                    <li>
                      <Link to="/abra-sua-conta">Contato</Link>
                    </li>
                  </div>
                  <div className="only-md">
                    <Link to="/abra-sua-conta" style={{ color: "#ffffff" }}>
                      CONTATO
                    </Link>
                  </div>
                </li>
              </Scrollspy>
            </nav>
            <div className="header-btn">
              <a
                className="btn-default btn-border btn-opacity"
                target="_blank"
                href="https://credito.verticalbank.com.br/"
              >
                <span>Entre</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
