import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import { Helmet } from "react-helmet";
import Footer from "../../component/footer/Footer";
import CustomLine from "../../elements/CustomLine";
import { BackButton } from "../../component/common/BackButton";

const blackLogoUrl = (
  <img
    style={{ width: "60%" }}
    src="/assets/images/svg/main_logo_black.png"
    alt="Logo header"
  />
);

export default function FundoDeInvestimento() {
  return (
    <div className="active-dark bg_color--10">
      <Helmet pageTitle="Vertical Bank - Fundo de Investimentos" />

      {/* Start Header Area  */}
      <Header />
      <BackButton />
      {/* End Header Area  */}
      {/* Start Slider Area   */}
      <div className="product-wrapper">
        <div
          className="product products-style fullscreen d-flex align-items-center justify-content-center bg_image bg_image--products-6"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="offset-1 col-lg-7">
                <div className="inner">
                  <CustomLine lines={[{ width: "80%" }, { width: "80%" }]} />
                  <h1 className="title">FUNDOS DE INVESTIMENTOS</h1>
                  <CustomLine lines={[{ width: "95%" }]} />
                  <p className="text" style={{ width: "65%" }}>
                    {" "}
                    Estruturação de Fundos para melhor governança do seu grupo
                    empresarial.
                  </p>
                  {/* <p className="text" style={{ width: "65%" }}>
                    {" "}
                    Alocamos seu recurso em cotas Mezanino e/ou cotas sênior,
                    garantindo maior proteção do seu recurso. Assim o maior
                    risco é assumido pelo Vertical Bank através de cotas
                    subordinadas.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="product products-bottom-section text-left d-flex align-items-center justify-content-center"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="inner w-100">
                <div className="offset-md-1 col-lg-12 fundos-right">
                  <div className="col-lg-6 text">
                    <p className="title">FIDC Exclusivo</p>
                    <CustomLine
                      color="black"
                      lines={[{ width: "50%" }, { width: "100%" }]}
                    />
                    <p className="text">
                      Estruturação e operacionalização de Fundo de Direito
                      Creditório com um único cedente ou único sacado. <br />{" "}
                      Destinado às grandes empresas que queiram criar seu
                      próprio veículo de financiamento.
                    </p>
                  </div>
                  <div className="col-lg-2 only-md">{blackLogoUrl}</div>
                </div>

                <div className="offset-md-1 col-lg-12 fundos-right">
                  <div className="col-lg-2 only-md">{blackLogoUrl}</div>
                  <div className="col-lg-10 text">
                    <p className="col-lg-4 offset-md-4 title">FIP</p>
                    <CustomLine
                      color="black"
                      orientation="center"
                      lines={[{ width: "40%" }]}
                    />
                    <CustomLine color="black" lines={[{ width: "85%" }]} />
                    <p className="col-lg-6 offset-md-4 text">
                      Estruturação de Fundo de Participações <br />
                      Destinado às alocações em Participações Societárias e
                      Private Equity
                    </p>
                  </div>
                </div>

                <div className="offset-md-1 col-lg-12 fundos-right">
                  <div className="col-lg-6 text">
                    <p className="col-lg-2 pl--0 title">Fundo Imobiliário</p>
                    <CustomLine
                      color="black"
                      lines={[{ width: "50%" }, { width: "100%" }]}
                    />
                    <p className="col-lg-10 pl--0 text">
                      Estruturação de Fundo para empreendimentos imobiliários
                      como CRIs, imóveis rurais ou comerciais e shoppings.
                    </p>
                  </div>
                  <div className="col-lg-2 only-md">{blackLogoUrl}</div>
                </div>

                <div className="offset-md-1 col-lg-12 fundos-right">
                  <div className="col-lg-2 only-md">{blackLogoUrl}</div>
                  <div className="col-lg-10 text">
                    <p className="col-lg-2 offset-md-4 title">
                      Fundo Multimercado{" "}
                    </p>
                    <CustomLine
                      color="black"
                      orientation="center"
                      lines={[{ width: "40%" }]}
                    />
                    <CustomLine color="black" lines={[{ width: "100%" }]} />
                    <p className="col-lg-5 offset-md-4 text">
                      Estruturação de Fundo Multimercado para melhor governança
                      empresarial e de liquidez. <br></br>Possibilidade de
                      alocação em múltiplas estruturas e produtos de
                      investimentos.
                    </p>
                    <CustomLine
                      className="offset-4"
                      orientation="center"
                      color="black"
                      lines={[{ width: "70%" }]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Slider Area   */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
}
