import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Sua abertura foi solicitada com sucesso. Em breve, entraremos em contato.
    </p>
  );
};
function FormStyle({ props }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_3sbkzyf",
        "template_4fw2pzw",
        e.target,
        "zHaTDzYaSArDLFyVV"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <div className="form-wrapper">
      <form action="" onSubmit={sendEmail}>
        <div className="rn-form-group pb--10">
          <input
            type="text"
            name="fullname"
            placeholder="Nome Completo"
            required
          />
        </div>

        <div className="rn-form-group pb--10">
          <input type="text" name="cnpj" placeholder="CNPJ" required />
        </div>

        <div className="rn-form-group pb--10">
          <input type="email" name="email" placeholder="Email" required />
        </div>

        <div className="rn-form-group pb--30">
          <label>
            Selecione um assunto
            <select
              id="favColor"
              type="produtos"
              name="produtos"
              placeholder="Produtos"
              required={true}
            >
              <option value="riscosacado">Risco Sacado</option>
              <option value="antecipacao">Antecipação de Recebíveis</option>
              <option value="fundos">Fundos de Investimentos</option>
              <option value="multifamilyoffice">Multi Family Office</option>
              <option value="corporate">Corporate Venture Capital</option>
              <option value="operacoes">Operações Estruturadas</option>
              <option value="credito">Crédito Consignado</option>
              <option value="outros">Outros</option>
            </select>
          </label>
        </div>

        <div className="rn-form-group pb--10">
          <input type="text" name="phone" placeholder="Telefone" required />
        </div>

        <div className="rn-form-group pb--10">
          <textarea type="text" name="topic" placeholder="Assunto" required />
        </div>

        <div className="rn-form-group" style={{ textAlign: "center" }}>
          <button
            className="btn-default"
            type="submit"
            value="submit"
            name="submit"
            id="mc-embedded-subscribe"
          >
            Enviar
          </button>
        </div>

        <div className="rn-form-group">{result ? <Result /> : null}</div>
      </form>
    </div>
  );
}
export default FormStyle;
