import React, { Component } from "react";

// orientation
// lar
// alt

class CustomLine extends Component {
  render() {
    const { orientation, color, lines } = this.props;

    return (
      <React.Fragment>
        <div
          className={`custom-line`}
          style={{ alignItems: `${orientation ?? "flex-start"}` }}
        >
          {lines &&
            lines.map((linha) => (
              <hr
                className="line"
                style={{
                  width: linha.width ?? "100%",
                  height: linha.height ?? "1px",
                  backgroundColor: color ?? "#D1D1D1",
                }}
              ></hr>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

export default CustomLine;
