import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { BackButton } from "../../component/common/BackButton";
import Footer from "../../component/footer/Footer";
import Header from "../../component/header/Header";
import CustomLine from "../../elements/CustomLine";

class OperacoesInfo extends Component {
  render() {
    const { title, text } = this.props;

    return (
      <React.Fragment>
        <div className="operacoes-info">
          <p className="operacoes-title">{title}</p>
          <p className="operacoes-text">{text}</p>
        </div>
      </React.Fragment>
    );
  }
}

export default function OperacoesEstruturadas() {
  var operacoes = [
    {
      title: "CRA",
      text: "Certificado de Recebíveis do Agronegócio",
    },
    {
      title: "CRI",
      text: "Certificado de Recebíveis Imobiliários",
    },
    {
      title: "Debêntures",
      text: "",
    },
    {
      title: "BTS",
      text: "Built to Suit",
    },
    {
      title: "SLB",
      text: "Sale & Lease Back",
    },
  ];

  return (
    <div className="active-dark bg_color--10">
      <Helmet pageTitle="Vertical Bank - Risco Sacado" />

      {/* Start Header Area  */}
      <Header />
      <BackButton></BackButton>
      {/* End Header Area  */}
      {/* Start Slider Area   */}
      <div className="product-wrapper">
        <div
          className="product products-style fullscreen d-flex bg_image bg_image--products-12"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="offset-1 col-lg-6">
                <div className="inner">
                  <CustomLine lines={[{ width: "80%" }, { width: "80%" }]} />
                  <h1 className="title">OPERAÇÕES ESTRUTURADAS</h1>
                  <CustomLine lines={[{ width: "100%" }]} />
                  <p className="text">
                    {" "}
                    Atuamos como originador e investidor em ofertas de crédito.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="slide slide-top-section text-left d-flex align-items-center justify-content-center pt_sm--0"
          data-black-overlay="6"
        >
          <div className="container pt--40">
            <CustomLine
              orientation="center"
              color="black"
              lines={[{ width: "70%" }]}
            />
            <CustomLine color="black" lines={[{ width: "70%" }]} />
            <div className="row">
              <div className="d-flex inner">
                <div className="offset-1 col-lg-4 col-sm-12">
                  <p className="operacoes-main-title">
                    Originador e investidor <br />
                    em ofertas
                  </p>
                </div>
                <div className="offset-1 col-lg-6 col-sm-11 pt_sm--20">
                  {operacoes &&
                    operacoes.map((item) => (
                      <OperacoesInfo title={item.title} text={item.text} />
                    ))}
                </div>
              </div>
              <div className="inner-lines">
                <div className="offset-1 col-lg-11">
                  <CustomLine
                    color="black"
                    lines={[{ width: "90%", heigth: "2px" }, { width: "80%" }]}
                  />
                  <CustomLine
                    color="black"
                    orientation="flex-end"
                    lines={[{ width: "60%" }]}
                  />
                  <CustomLine color="black" lines={[{ width: "70%" }]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Slider Area   */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
}
