import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import Header from "../component/header/Header";
import Helmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import CustomLine from "../elements/CustomLine";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useEffect } from "react";

const main = {
  textPosition: "text-left",
  title: "Vertical Bank",
  description: "Sede da Holding em Patos de Minas-MG.",
};

const blackLogoUrl = (
  <img
    src="/assets/images/svg/main_logo_black.png"
    alt="Logo header"
    className="black-logo-url"
  />
);
const whiteLogoUrl = (
  <img
    className="white-logo-img"
    src="/assets/images/svg/logo_padrao_white.png"
    alt="Logo header"
  />
);
const corporateBankUrl = (
  <img
    className="offset-md-1 left-logo-img"
    src="/assets/images/svg/corporate_finance@3x.png"
    alt="Logo header"
  />
);
const verticalBankUrl = (
  <img
    src="/assets/images/svg/vertical_bank_text_logo.png"
    alt="Logo header"
    className="vertical-bank-url"
  />
);

const apiKey = "418a6d93";

class MarketInformation extends Component {
  render() {
    const { name, variation, value, location } = this.props;

    var now = new Date();

    return (
      <React.Fragment>
        <div className="symbol-data pb--20 pt--20 pr--10">
          <div className="symbol-data-name">
            <p className="title">{name}</p>
            <p className="info">
              {location ? `${location} - ` : ""}
              {now.getHours()}:{now.getMinutes()}
            </p>
          </div>
          <div className="symbol-data-value pl--20">
            <p className="value">{value}</p>
            <p className="variation">
              {variation}%{" "}
              {variation > 0 ? (
                <FaCaretUp style={{ color: "green" }} />
              ) : (
                <FaCaretDown style={{ color: "red" }} />
              )}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class Home extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      stocks: [],
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    fetch("https://api.hgbrasil.com/finance?key=418a6d93&format=json-cors", {
      mode: "cors",
    })
      .then((response) => response.json())
      .then((result) => {
        var dataInfo = result;
        var stocks = [
          { ...dataInfo.results.currencies["USD"], name: "DOLAR" },
          dataInfo.results.currencies["EUR"],
          {
            name: "CDI",
            variation: 0,
            buy: dataInfo.results.taxes[0]["cdi_daily"],
          },
          {
            ...dataInfo.results.stocks["IBOVESPA"],
            name: "IBOVESPA",
            location: "Brasil",
          },
          {
            ...dataInfo.results.stocks["DOWJONES"],
            name: "DOW JONES",
            location: "EUA",
          },
          {
            ...dataInfo.results.stocks["NASDAQ"],
            name: "NASDAQ",
            location: "EUA",
          },
        ];

        this.setState({ stocks: stocks });
      })
      .catch((data) => console.log(data));
  }

  render() {
    return (
      <div className="active-dark">
        <Helmet pageTitle="Vertical Bank - Home" />

        {/* Start Header Area  */}
        <div>
          <Header />
        </div>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="home-wrapper">
          {/* Start Single Slide */}
          <div
            className="home home-style-2 fullscreen bg_image bg_image--main-1 pb_sm--0"
            data-black-overlay="6"
          >
            <div className="container">
              <div className="row mt--100 mb_--40 mt_sm--30">
                <div className="offset-md-8 col-lg-4">
                  <div className="image-top-right">{blackLogoUrl}</div>
                  <div className="text-top-right">{verticalBankUrl}</div>
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-8 col-sm-12 mt_sm--40">
                  <div className={`inner ${main.textPosition}`}>
                    <CustomLine lines={[{ width: "70%" }]} />
                    {corporateBankUrl}
                    <CustomLine
                      lines={[{ width: "90%" }]}
                      className="mt_md-20"
                    />
                    <p className="offset-md-1 description col-lg-7">
                      {main.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Single Slide */}
        </div>
        {/* End Slider Area   */}

        {/* Start portfolio Area  */}
        <div className="container rn-portfolio-area">
          <div className="row-mid-banner">
            <h2 className="title">
              Mercado de Capitais <br></br>para quem pensa alto.
            </h2>
            <div className="pl--20">{whiteLogoUrl}</div>
            <div className="last-group">
              <div className="only-md">
                <CustomLine
                  orientation="flex-end"
                  lines={[
                    { width: "100%" },
                    { width: "50%" },
                    { width: "70%" },
                  ]}
                />
              </div>
              <p className="mid-description align-start pt_sm--20">
                conheça <br />
                mais nossos <br />
                serviços.
              </p>
            </div>
          </div>
        </div>

        <div
          className="wrapper ptb--50 plr--30 ptb_sm--0"
          style={{ backgroundColor: "white" }}
        >
          <div className="row">
            <PortfolioMasonry
              item="7"
              column="col-lg-1 col-md-4 col-sm-4 col-12 portfolio-tilthover"
            />
          </div>
        </div>
        <div
          className="about abouts-style fullscreen d-flex justify-content-center bg_image bg_image--about-2"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="offset-md-6 col-lg-5 col-sm-12">
                <div className="inner">
                  <p className="text">
                    {" "}
                    Estamos construindo um novo ambiente no mercado financeiro:
                    mais próximo, mais ágil e mais receptivo à negócios. Com
                    melhor relação entre grandes e pequenas empresas, novas
                    ideias de relacionamento e de crédito.
                  </p>
                  <CustomLine lines={[{ width: "105%" }]} />
                  <CustomLine lines={[{ width: "105%" }]} />
                  <h1 className="title-middle">Relacionamento com propósito</h1>
                  <CustomLine lines={[{ width: "105%" }]} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-wrapper">
          {/* Start Single Slide */}
          <div
            className="home home-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--main-3"
            data-black-overlay="6"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-sm-12">
                  <div className={`inner left`}>
                    <CustomLine
                      orientation="flex-end"
                      lines={[{ width: "70%" }, { width: "90%" }]}
                    />
                    <p className="description-bottom">
                      Não existe <br /> crédito maior <br /> do que o <br />{" "}
                      relacionamento.
                    </p>
                    <CustomLine
                      orientation="flex-end"
                      lines={[{ width: "84%" }]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="currency-section">
            <div className="container">
              <div className="row">
                <div className="offset-1 col-lg-9 ptb--20">
                  <p className="currency-title">Cotações_</p>
                  <div className="symbol">
                    {this.state.stocks &&
                      this.state.stocks.map((item) => (
                        <MarketInformation
                          name={item?.name}
                          variation={item.variation}
                          value={item.buy ?? item.points}
                          location={item.location}
                          key={item.name}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Single Slide */}
        </div>
        {/* End portfolio Area  */}

        {/* Start Footer Area  */}
        <Footer />
        {/* End Footer Area  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </div>
    );
  }
}
export default Home;
