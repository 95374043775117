import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
const logoUrl = (
  <img src="/assets/images/svg/corporate_finance_black.png" alt="Logo Footer" />
);

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/",
    title: "Facebook",
  },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/vertical-bank/",
    title: "LinkedIn",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/",
    title: "Instagram",
  },
  { Social: <FaTwitter />, link: "https://twitter.com/", title: "Twitter" },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area footer-style">
          {/* Start Footer Area  */}
          <div className="footer-wrapper container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="ft-image">
                  <div className="logo">
                    <Link to="/">{logoUrl}</Link>
                    <div className="social-share-inner">
                      <ul className="social-share social-style--2 mt--20 liststyle">
                        <li>
                          <a href={`${SocialShare[1].link}`}>
                            {SocialShare[1].Social}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-link">
                  <h4>Atendimento</h4>
                  <div className="d-flex">
                    <ul className="col-lg-8 ft-text-left">
                      <span>34 2589-8309</span>
                      <p>34 99778-4500</p>
                    </ul>
                    <ul className="col-lg-4 ft-text-right">
                      <span>fixo</span>
                      <span>whatsapp</span>
                    </ul>
                  </div>
                </div>
                <span className="ft-text-atendimento">
                  <Link to="/">faleconosco@verticalbank.com.br</Link>
                </span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 col-12 mt_md--40 mt_sm--40">
                <div className="footer-link">
                  <h4>Uberlândia - MG</h4>
                  <ul className="ft-link">
                    <li>
                      Av. Rondon Pacheco, 4600<br></br>
                      Center Shopping - UBT<br></br>
                      18º Andar - Sala 185<br></br>
                      Tibery, 38405-142
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-12 col-sm-12 col-12 mt_md--40 mt_sm--40">
                <div className="footer-link">
                  <h4>Patos de Minas - MG</h4>
                  <ul className="ft-link">
                    <li>
                      Av. Marabá, 3095 <br></br>
                      2º Andar - Sala 185<br></br>
                      Bela Vista, 38703-236
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                <div className="Logo">
                  <img
                    src="/assets/images/logo/logofooter2.png"
                    alt="Logo footer"
                  />
                </div>
                <div className="ft-text-lgpd">
                  <p>
                    {" "}
                    Seguimos regras e procedimentos de controle interno para Lei
                    Geral de Proteção de Dados (LGPD) e Prevenção à Lavagem de
                    Dinheiro e Financiamento ao terrorismo (PLDFT) Conforme
                    Circular nº3.978 de 23/1/2020.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End Footer Area  */}
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
