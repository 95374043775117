import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Helmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import FormStyle from "../elements/contact/FormStyle";
import { BackButton } from "../component/common/BackButton";

class Account extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div className="active-dark bg_color--10">
        <Helmet pageTitle="Vertical Bank - Abra Sua Conta" />

        {/* Start Header Area  */}
        <Header />
        <BackButton />
        {/* End Header Area  */}
        <div className="contact-form--1">
          <div className="pl--0 pr--0 w-100">
            <div className="align-items-start d-flex fullscreen bg_image bg_image--products-13">
              <div className="container">
                <div className="offset-6 col-lg-6 order-1 order-lg-1">
                  <div className="thumbnail mb_md--30 mb_sm--30"></div>
                </div>
                <div className="offset-md-7 col-lg-5 order-2 order-lg-2">
                  <div className="section-title text-left ptb--30">
                    <p className="title">
                      Preencha o formulário abaixo para entrar em contato.
                    </p>
                  </div>
                  <FormStyle />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Footer Area  */}
        <Footer />
        {/* End Footer Area  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </div>
    );
  }
}
export default Account;
