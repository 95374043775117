import React, { Component, useState } from "react";
import { Link } from "react-router-dom";

function PortfolioItem(props) {
  const plusIconUrl = (
    <img src="/assets/images/svg/icon_plus_gray.png" alt="Logo header" />
  );
  const [openInfo, setOpenInfo] = useState(false);
  const { index, value } = props;

  return (
    <div key={index} className={openInfo ? "open-info" : "close-info"}>
      {!openInfo && (
        <div className="portfolio">
          <div
            to={value.url}
            className="plus-image"
            onMouseOver={() => setOpenInfo(!openInfo)}
          >
            {plusIconUrl}
          </div>
          <div className="title">
            <Link to={value.url}>{value.title}</Link>
          </div>
          <div className="description">
            <Link to={value.url}>{value.description}</Link>
          </div>
        </div>
      )}
      {openInfo && (
        <div
          className="portfolio-opened"
          onMouseLeave={() => setOpenInfo(!openInfo)}
        >
          {/* <div to={value.url} className="opened-icon" onClick={() => setOpenInfo(!openInfo)}>
                x
                </div> */}
          <div className="opened-title">{value.resume}</div>
          <Link className="opened-description" to={value.url}>
            <div style={{ bottom: 0 }}>Saiba Mais</div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default PortfolioItem;
