import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <meta name="robots" content="noindex, follow" />
          <meta name="description" content="Vertical Bank" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            http-equiv="Content-Security-Policy"
            content="default-src * 'unsafe-inline' 'unsafe-eval'; script-src * 'unsafe-inline' 'unsafe-eval'; connect-src * 'unsafe-inline'; img-src * data: blob: 'unsafe-inline'; frame-src *; style-src * 'unsafe-inline';"
          />
          {/* <meta
            http-equiv="Content-Security-Policy"
            content="style-src 'self' https://fonts.googleapis.com; font-src 'self' https://fonts.gstatic.com;"
          />
          <meta
            http-equiv="Content-Security-Policy"
            content="default-src 'self'; script-src 'self' 'unsafe-inline' https:; manifest-src 'self'"
          /> */}
        </Helmet>
      </React.Fragment>
    );
  }
}
PageHelmet.propTypes = {
  title: PropTypes.string,
};
export default PageHelmet;
