// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import PageScrollTop from "./component/PageScrollTop";
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Home from "./pages/Home";
import About from "./pages/About";
import RiscoSacado from "./pages/Pruducts/RiscoSacado";
import AntecipacaoRecebiveis from "./pages/Pruducts/AntecipacaoRecebiveis";
import PropositosValores from "./pages/PropositosValores";
import Produtos from "./pages/Produtos";
import FundoDeInvestimento from "./pages/Pruducts/FundodeInvestimento";
import MultiFamlityOffice from "./pages/Pruducts/Multi Family Office";
import CorporateVentureCapital from "./pages/Pruducts/CorporateVentureCapital";
import OperacoesEstruturadas from "./pages/Pruducts/Operações Estruturadas";
import CreditoConsignado from "./pages/Pruducts/Créditoconsignado";
import Account from "./pages/Account";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/sobre`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/valores`}
              component={PropositosValores}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/produtos`}
              component={Produtos}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/abra-sua-conta`}
              component={Account}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/produtos/risco-sacado`}
              component={RiscoSacado}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/produtos/antecipacao-recebiveis`}
              component={AntecipacaoRecebiveis}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/produtos/fundo-investimentos`}
              component={FundoDeInvestimento}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/produtos/multi-family-office`}
              component={MultiFamlityOffice}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/produtos/corporate-venture`}
              component={CorporateVentureCapital}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/produtos/operacoes-estruturadas`}
              component={OperacoesEstruturadas}
            />
            {/* <Route exact path={`${process.env.PUBLIC_URL}/produtos/credito-consignado`} component={CreditoConsignado}/> */}

            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
