import React, { Component, useState } from "react";
import PortfolioItem from "./portfolioItem";

const PortfolioList = [
  {
    category: "Development",
    title: "Risco Sacado",
    description: "Antecipação á fornecedores",
    url: "/produtos/risco-sacado",
    resume: [
      "Antecipação para fornecedores de Grandes Empresas.",
      <br />,
      "O produto certo para melhorar o relacionamento com seu fornecedor.",
    ],
  },
  {
    category: "Product Design",
    title: "Antecipação de Recebíveis",
    description: "FIDC",
    url: "/produtos/antecipacao-recebiveis",
    resume:
      "Geração de caixa imediato. Você antecipa o recebimento de suas vendas a prazo num ambiente seguro, ágil e com taxas atrativas.",
  },
  {
    category: "Application",
    title: "Fundos de Investimentos",
    description:
      "Fundos Exclusivos, Multimercado, Imobiliário e de Participações.",
    url: "/produtos/fundo-investimentos",
    resume: [
      "Estruturação de Fundos para melhor governança FIP, FIM, FII, FIDC. ",
      <br />,
      "Estruturação e operacionalização de FIDC exclusivo.",
    ],
  },
  {
    category: "Application",
    title: "Multi Family Office",
    description: "Gestão de patrimônio",
    url: "/produtos/multi-family-office",
    resume: [
      "Preservação de capital, eficiência financeira e legado.",
      <br />,
      "Gerimos seu patrimônio de forma ética e profissional e, principalmente, conforme o seu perfil.",
    ],
  },
  {
    category: "Application",
    title: "Corporate Venture Capital",
    description: "Aquisição e investimentos em empresas",
    url: "/produtos/corporate-venture",
    resume:
      "Investimento destinado à aceleração de empresas. Atuamos nos segmentos Agro e Financeiro.",
  },
  {
    category: "Application",
    title: "Operações Estruturadas",
    description: "CRI, CRA, BTS e M&A",
    url: "/produtos/operacoes-estruturadas",
    resume: "Atuamos como originador e investidor em ofertas de crédito.",
  },
  // {
  //   category: "Application",
  //   title: "Crédito Consignado",
  //   description: "",
  //   url: "/produtos/credito-consignado",
  //   resume:
  //     "Crédito rápido para seus colaboradores, a melhor forma de se aproximar e de auxiliar que está com você no seu dia-a-dia.",
  // },
];

function PortfolioMasonry(props) {
  const list = PortfolioList;

  return (
    <div className="container d-flex m-flex-wrap">
      {list.map((value, index) => (
        <PortfolioItem index={index} value={value} />
      ))}
    </div>
  );
}
export default PortfolioMasonry;
