import brandOne from "../../public/assets/images/logo/agcore.png";
import agrioito from "../../public/assets/images/logo/white/agri.png";
import biogera from "../../public/assets/images/logo/white/biogera.png";
import brandFour from "../../public/assets/images/logo/concept.png";
import brandFive from "../../public/assets/images/logo/gts.png";
import brandSix from "../../public/assets/images/logo/seek.png";
import segredo from "../../public/assets/images/logo/white/segredo.png";
import transgraos from "../../public/assets/images/logo/transgraos.png";
import ts from "../../public/assets/images/logo/tsagricola.png";
import valorem from "../../public/assets/images/logo/valorem.png";
import verticalbank from "../../public/assets/images/logo/verticalbank.png";
import verticalinvestimentos from "../../public/assets/images/logo/verticalinvestimentos.png";
import React, { useState } from "react";

//vertical vertical transgraos  valorant ts agricola segredo biogera agrioito
import Slider from "react-slick";
const images = [
  verticalbank,
  verticalinvestimentos,
  transgraos,
  valorem,
  ts,
  verticalbank,
  verticalinvestimentos,
  segredo,
  biogera,
  agrioito,
];

const BrandSlider = () => {
  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    arrows: false,
    infinite: true,
    lazyload: true,
    speed: 400,
    slidesToShow: 5,
    centerMode: true,
    centerPadding: 120,
    autoplay: true,
    beforeChange: (current, next) => setImageIndex(next),

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          centerPadding: 120,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider">
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div
            className={
              idx === imageIndex ? "logo-slide logo-activeSlide" : "logo-slide"
            }
          >
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BrandSlider;
