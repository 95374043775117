import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Helmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import { BackButton } from "../component/common/BackButton";

class Produtos extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div className="active-dark bg_color--10">
        <Helmet pageTitle="Vertical Bank - Produtos" />

        {/* Start Header Area  */}
        <Header />
        <BackButton />
        {/* End Header Area  */}
        <div
          className="product products-top-section text-left d-flex align-items-center justify-content-center"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="inner">
                <p className="offset-md-1 col-lg-10 text pl--0">
                  Estamos focados em encontrar soluções, sair do convencional e
                  estruturar o melhor caminho de crédito e de investimentos para
                  o seu negócio.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="wrapper ptb--50 plr--30 pt_sm--0 pb_sm--0"
          style={{ backgroundColor: "#E9E9E9" }}
        >
          <div className="row">
            <PortfolioMasonry
              item="7"
              column="col-lg-1 col-md-4 col-sm-4 col-12 portfolio-tilthover"
            />
          </div>
        </div>

        {/* Start Footer Area  */}
        <Footer />
        {/* End Footer Area  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </div>
    );
  }
}
export default Produtos;
