import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import { Helmet } from "react-helmet";
import Footer from "../../component/footer/Footer";
import CustomLine from "../../elements/CustomLine";
import { BackButton } from "../../component/common/BackButton";

export default function AntecipacaoRecebiveis() {
  return (
    <div className="active-dark bg_color--10">
      <Helmet pageTitle="Vertical Bank - Antecipação Recebíveis" />

      {/* Start Header Area  */}
      <Header />
      <BackButton />

      {/* End Header Area  */}
      {/* Start Slider Area   */}
      <div className="product-wrapper">
        {/* Start Single Slide */}
        <div
          className="product products-style fullscreen d-flex align-items-center justify-content-center bg_image bg_image--products-5"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="offset-1 col-lg-6">
                <div className="inner">
                  <CustomLine lines={[{ width: "90%" }, { width: "90%" }]} />
                  <h1 className="risco-title">
                    ANTECIPAÇÃO <br />
                    DE RECEBÍVEIS
                  </h1>
                  <CustomLine lines={[{ width: "100%" }]} />
                  <p className="sub-title">
                    {" "}
                    FIDC (FUNDO DE INVESTIMENTOS DE DIREITO CREDITÓRIO)
                  </p>
                  <CustomLine lines={[{ width: "100%" }]} />
                  <p className="description">
                    {" "}
                    Antecipação de Duplicatas, Contratos, CPRs e CCBs.{" "}
                  </p>
                  <p className="text">
                    {" "}
                    Geração de caixa imediato. <br />
                    Você antecipa o recebimento de suas vendas a prazo num
                    ambiente seguro, ágil e com taxas atrativas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Single Slide */}
      </div>

      {/* End Slider Area   */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
}
