import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { BackButton } from "../../component/common/BackButton";
import Footer from "../../component/footer/Footer";
import Header from "../../component/header/Header";
import CustomLine from "../../elements/CustomLine";

const bottomImageUrl = (
  <img src="/assets/images/products/products_16.png" alt="Logo header" />
);

class PairInformation extends Component {
  render() {
    const { title, text } = this.props;

    return (
      <React.Fragment>
        <div className="credito-pair-info">
          <p className="credito-pair-title">{title}</p>
          <p className="credito-pair-text">{text}</p>
        </div>
      </React.Fragment>
    );
  }
}

export default function CreditoConsignado() {
  var firstColumnTexts = [
    {
      title: "Proximidade com Colaboradores",
      text: "Auxílio para seu maior patrimônio, as pessoas",
    },
    {
      title: "Plataforma Exclusiva",
      text: "Segurança de dados cadastrais",
    },
    {
      title: "Ambiente 100% digital",
      text: "Sem necessidade de equipe dedicada ao processo",
    },
  ];

  var secondColumnTexts = [
    {
      title: "Ambiente 100% Digital",
      text: "Sem exposição do seu colaborador",
    },
    {
      title: "Crédito descomplicado. Fácil e rápido",
      text: "Colaborador tem acesso imediato às condições aprovadas",
    },
    {
      title: "Taxas Competitivas",
      text: "Valores que se encaixam no seu bolso",
    },
  ];

  return (
    <div className="active-dark bg_color--10">
      <Helmet pageTitle="Vertical Bank - Crédito Consignado" />

      {/* Start Header Area  */}
      <Header />
      <BackButton></BackButton>
      {/* End Header Area  */}
      {/* Start Slider Area   */}
      <div className="product-wrapper">
        <div
          className="product products-style fullscreen d-flex align-items-center justify-content-center bg_image bg_image--products-14"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="offset-1 col-lg-6">
                <div className="inner">
                  <CustomLine lines={[{ width: "80%" }, { width: "80%" }]} />
                  <h1 className="title">CRÉDITO HOT MONEY CONSIGNADO</h1>
                  <CustomLine lines={[{ width: "100%" }]} />
                  <p style={{ width: "80%" }} className="text">
                    {" "}
                    Crédito rápido para seus colaboradores, a melhor forma de se
                    aproximar e de auxiliar que está com você no seu dia-a-dia.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="header-line" />

        <div className="container">
          <div className="row pb--50">
            <div className="offset-1 col-4 pt--120">
              <h1> Para a empresa âncora</h1>

              {firstColumnTexts &&
                firstColumnTexts.map((item) => (
                  <PairInformation title={item.title} text={item.text} />
                ))}
            </div>

            <div className="col-3 plr--0">{bottomImageUrl}</div>

            <div className="col-4 pt--120">
              <h1> Para os colaboradores</h1>

              {secondColumnTexts &&
                secondColumnTexts.map((item) => (
                  <PairInformation title={item.title} text={item.text} />
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* End Slider Area   */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
}
