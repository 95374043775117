import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import Header from "../component/header/Header";
import Helmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import CustomLine from "../elements/CustomLine";
import BrandSlider from "../component/BrandSlider";
import { BackButton } from "../component/common/BackButton";

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    title: "Corporate Bank",
    description:
      "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

const blackLogoUrl = (
  <img src="/assets/images/svg/main_logo_black.png" alt="Logo header" />
);
const whiteLogoUrl = (
  <img
    className="white-logo"
    src="/assets/images/svg/logo_padrao_white.png"
    alt="Logo header"
  />
);
const corporateBankUrl = (
  <img
    className="corporate-bank-logo"
    src="/assets/images/svg/corporate_finance@3x.png"
    alt="Logo header"
  />
);
const verticalBankUrl = (
  <img
    className="vertical-bank-logo"
    src="/assets/images/svg/logo_vertical_white_text.png"
    alt="Logo header"
  />
);

class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div className="active-dark bg_color--10">
        <Helmet pageTitle="Vertical Bank - Sobre" />

        {/* Start Header Area  */}
        <Header />
        <BackButton />
        {/* End Header Area  */}
        {/* Start Slider Area   */}
        <div className="about-wrapper">
          {/* Start Single Slide */}
          <div
            className="about abouts-style text-left fullscreen d-flex justify-content-center bg_image bg_image--about-1"
            data-black-overlay="6"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="inner top-negative">
                    {whiteLogoUrl}
                    <div className="ml--80">{verticalBankUrl}</div>
                  </div>
                </div>
                <div className="offset-md-2 col-lg-6">
                  <div className="inner ptb--10">
                    <h1 className="title col-lg-12 pl--0 pr--10 mt_sm--40">
                      Somos o braço financeiro do grupo Vertical Investimentos,
                      criado para gerar oportunidades em estruturas de crédito e
                      de investimentos para o mercado Middle, Corporate e em
                      especial para o Agronegócio.
                    </h1>
                  </div>
                  <CustomLine lines={[{ width: "65%" }]} />
                  <div className="pb--10">{corporateBankUrl}</div>
                  <CustomLine lines={[{ width: "85%" }]} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="about abouts-green-section d-flex align-items-center justify-content-center"
            data-black-overlay="6"
          >
            <div className="container">
              <BrandSlider></BrandSlider>
            </div>
          </div>
          <div
            className="about abouts-style text-left fullscreen d-flex justify-content-center bg_image bg_image--about-3"
            data-black-overlay="6"
          >
            <div className="container">
              <div className="row">
                <div className="offset-md-3 col-lg-8 col-sm-4">
                  <div className="inner text-right">
                    <div className="offset-md-6 text-left mb--20">
                      <h1 className="title">Janelas abertas</h1>
                      <h1 className="title">para o futuro</h1>
                    </div>
                    <div className="col-lg-12 col-sm-8 pr--0">
                      <CustomLine
                        lines={[{ width: "106.5%", height: "1px" }]}
                      />
                    </div>
                    <div className="offset-md-6 col-lg-7 text-left pl--0">
                      <CustomLine
                        orientation="flex-end"
                        lines={[{ width: "100%", height: "1px" }]}
                      />
                      <p className="col-lg-10 text">
                        {" "}
                        Referência de excelência, temos origem de um grupo
                        sólido, com vocação para o Agro, criado para que nossos
                        clientes e parceiros alcancem seu potencial.
                      </p>
                      <CustomLine
                        orientation="flex-end"
                        lines={[{ width: "100%", height: "1px" }]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Single Slide */}
        </div>

        {/* End Slider Area   */}

        {/* Start Footer Area  */}
        <Footer />
        {/* End Footer Area  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </div>
    );
  }
}
export default About;
