import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Helmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import CustomLine from "../elements/CustomLine";
import { BackButton } from "../component/common/BackButton";

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    title: "Corporate Bank",
    description:
      "Uma nova visão sobre o mercado financeiro. Onde é possível unir credibilidade, qualidade, agilidade e geração de valor. Por isso valorizamos tanto a segurança nos nossos negócios, a simplicidade no jeito de fazer e a efetividade para atingir objetivos.",
    logo: "/assets/images/svg/logo_green_2_7.png",
  },
  {
    textPosition: "text-left",
    category: "Segurança",
    title: "Corporate Bank",
    description:
      "Nos preocupamos com a continuidade dos negócios, a preservação de dados e em construir um ambiente confiável, íntegro e perene.",
    logo: "/assets/images/svg/logo_green_3_9.png",
  },
  {
    textPosition: "text-left",
    category: "Simplicidade",
    title: "Corporate Bank",
    description:
      "Valorizamos a praticidade, o entendimento e o foco na solução para alcançarmos a excelência.",
    logo: "/assets/images/svg/logo_green_5_11.png",
  },
  {
    textPosition: "text-left",
    category: "Efetividade",
    title: "Corporate Bank",
    description:
      "Buscamos o melhor resultado, da melhor maneira possível. Impactando positivamente a nossa organização, os nossos clientes e a sociedade.",
    logo: "/assets/images/svg/logo_green_12_6.png",
  },
];

class PropositosValores extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div className="active-dark bg_color--10">
        <Helmet pageTitle="Vertical Bank - Propósitos e Valores" />

        {/* Start Header Area  */}

        <Header />
        <BackButton />
        {/* End Header Area  */}

        <div
          className="slide slide-top-section text-left d-flex align-items-center justify-content-center"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="inner">
                <p className="offset-md-1 col-lg-4 text">
                  Em nossa relação trabalhamos com propósito.
                </p>
              </div>
              <div className="inner-lines">
                <div className="offset-1 col-lg-11 pl--0">
                  <CustomLine color="black" lines={[{ width: "80%" }]} />
                  <CustomLine
                    color="black"
                    orientation="flex-end"
                    lines={[{ width: "60%" }]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation slider-startup">
            <Slider
              autoplay={true}
              autoplaySpeed={5000}
              className="rn-slick-dot dot-light"
              {...slideSlick}
            >
              {SlideList.map((value, index) => (
                // Start Single Slider
                <div
                  className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image--products-${
                    index + 1
                  }`}
                  key={index}
                  data-black-overlay="8"
                >
                  <div className="container">
                    <div className="row">
                      <div className="offset-md-1 col-lg-7 col-sm-12">
                        <div className={`inner ${value.textPosition}`}>
                          <CustomLine
                            color="white"
                            className="pb--10 line"
                            lines={[
                              { width: "50%", heigth: "2px" },
                              { width: "60%", heigth: "2px" },
                            ]}
                          />
                          <img src={`${value.logo}`} alt="Test"></img>
                          {value.category ? (
                            <p className="category">{value.category}</p>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          <CustomLine
                            color="white"
                            lines={[
                              { width: "80%", heigth: "2px" },
                              { width: "100%", heigth: "2px" },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                // End Single Slider
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Footer Area  */}

        {<Footer />}

        {/* End Footer Area  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </div>
    );
  }
}
export default PropositosValores;
